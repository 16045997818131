import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/DefaultLayout.js";
import Props from 'components/Props';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "componentName=TextField",
        "componentName": "TextField"
      }}>{`<TextField id="textfield" label="Label" placeholder="Placeholder" />
`}</code></pre>
    <h2 {...{
      "id": "props",
      "style": {
        "position": "relative"
      }
    }}>{`Props`}</h2>
    <Props identifier="TextField" mdxType="Props" />
    <h2 {...{
      "id": "action",
      "style": {
        "position": "relative"
      }
    }}>{`Action`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`Adds an action or extra information to your item, mostly used for links.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<TextField
  id="action"
  label="Label"
  placeholder="Placeholder"
  action={<LinkText href="#">LinkText</LinkText>}
/>
`}</code></pre>
    <h2 {...{
      "id": "optionallabel-required-fields",
      "style": {
        "position": "relative"
      }
    }}>{`OptionalLabel (Required fields)`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`By default the TextField is always `}<strong parentName="p">{`required`}</strong>{`. To make a TextField optional
you can add the `}<inlineCode parentName="p">{`optionalLabel`}</inlineCode>{` prop.`}</p>
    <p>{`With Chameleon we take a specific approach where we encourage marking optional
fields, not required. The majority of fields in a form are always required.
Indicating that fields are required make users more fearful, it increases the
risk of errors and reduces the form completion rate.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<TextField
  id="optional-label"
  label="Label"
  placeholder="Placeholder"
  optionalLabel="Optional"
/>
`}</code></pre>
    <h2 {...{
      "id": "error",
      "style": {
        "position": "relative"
      }
    }}>{`Error`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`To convey an error to the user, you can set the `}<inlineCode parentName="p">{`error`}</inlineCode>{` prop to true.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<TextField id="error" label="Label" placeholder="Placeholder" error />
`}</code></pre>
    <h2 {...{
      "id": "success",
      "style": {
        "position": "relative"
      }
    }}>{`Success`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`To convey a success state to the user, you can set the `}<inlineCode parentName="p">{`success`}</inlineCode>{` prop to true.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<TextField id="success" label="Label" placeholder="Placeholder" success />
`}</code></pre>
    <h2 {...{
      "id": "disabled",
      "style": {
        "position": "relative"
      }
    }}>{`Disabled`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`Marking a TextField as `}<inlineCode parentName="p">{`disabled`}</inlineCode>{`, will disable all interaction with the
element.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<TextField id="disabled" label="Label" placeholder="Placeholder" disabled />
`}</code></pre>
    <h2 {...{
      "id": "iconleft",
      "style": {
        "position": "relative"
      }
    }}>{`IconLeft`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`For further illustration purposes, you can provide an `}<inlineCode parentName="p">{`iconLeft`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<TextField
  id="icon-left"
  label="Label"
  placeholder="Placeholder"
  iconLeft={Search}
/>
`}</code></pre>
    <h2 {...{
      "id": "labelhidden",
      "style": {
        "position": "relative"
      }
    }}>{`LabelHidden`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`To meet accessibility requirements, every TextField should have an associated
label. We do support the ability to visually hide the label when the TextField's
purpose is clear.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<TextField
  id="label-hidden"
  label="Label"
  labelHidden
  placeholder="Placeholder"
/>
`}</code></pre>
    <h2 {...{
      "id": "message",
      "style": {
        "position": "relative"
      }
    }}>{`Message`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`Give additional information to the user.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Box mb={5}>
  <TextField
    id="message"
    label="Label"
    placeholder="Placeholder"
    message="Multiple inputs should have space 5 between them."
  />
</Box>
<Box>
  <TextField
    id="message-1"
    label="Label"
    placeholder="Placeholder"
    message="Multiple inputs should have space 5 between them. Oh, we've got error."
    error
  />
</Box>
`}</code></pre>
    <h2 {...{
      "id": "mask",
      "style": {
        "position": "relative"
      }
    }}>{`Mask`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`To visually aid the user with the expected format, you can use an input mask.
Input masks will display a template of the expected value. The mask is built out
of several definitions:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`9`}</inlineCode>{`: numeric`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`a`}</inlineCode>{`: alphabetical`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`*`}</inlineCode>{`: alphanumeric`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<TextField
  id="optional-label"
  label="Enter your billing details"
  mask="BE99 9999 9999 9999"
/>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      